/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { getInstance } from './authWrapper'

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = () => {
    if (authService.isAuthenticated) {
      return next()
    }

    // authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    authService.loginWithRedirect({ appState: { targetUrl: '/' } })
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
