<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img :src="$auth.user.picture"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img :src="$auth.user.picture"></v-img>
          </v-avatar>
        </v-badge>
      </div>

      <v-divider></v-divider>
      <!-- Profile -->
      <!-- <v-list-item to="/pages/profile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Team -->
      <!-- <v-list-item to="/pages/team">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountMultiplePlus }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Team</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider> -->

      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiWifi,
  mdiWifiOff,
  mdiAccountMultiplePlus,
} from '@mdi/js'

export default {
  data() {
    return {
      isConnected: true,
      componentKeyRefreshStatus: 0,
    }
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiWifi,
        mdiWifiOff,
        mdiAccountMultiplePlus,
      },
    }
  },
  async created() {},
  async mounted() {},
  methods: {
    resetpage() {
      location.reload()
    },
    forceRerender() {
      this.componentKeyRefreshStatus += 1
    },
    logout() {
      this.$auth.logout({
        returnTo: 'https://signup.pharmaguide.ca',
        // returnTo: 'http://localhost:8081/',
        // 'http://localhost:8080/login',
      })
    },

    //     logout() {
    //   this.$auth.logout();
    //   this.$router.push({ path: '/login' });
    // },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>



