import '@/plugins/vue-composition-api'

// import '@/styles/styles.scss';
import '@sum.cumo/vue-datepicker/dist/Datepicker.css'
import Vue from 'vue'
import VueIntercom from 'vue-intercom'
import { audience, clientId, domain } from '../auth_config.json'
import App from './App.vue'
import './assets/tailwind.css'
import { Auth0Plugin } from './auth'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueIntercom, { appId: 'klc1sn1m' })

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
