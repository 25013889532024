import { mdiDesktopClassic, mdiNotebookOutline, mdiRemoteDesktop } from '@mdi/js';

export default [
  {
    subheader: 'Onboarding',
  },
  {
    title: 'Plans',
    icon: mdiNotebookOutline,
    to: 'home',
  },
  // {
  //   title: 'FAQ',
  //   icon: mdiFrequentlyAskedQuestions,
  //   // to: 'faq',
  //   badge: 'Soon',
  //   badgeColor: 'success',
  // },
  {
    title: 'Installation',
    icon: mdiDesktopClassic,
    to: 'enrollment',
  },
  {
    subheader: 'Support',
  },
  {
    title: 'Support Client',
    icon: mdiRemoteDesktop,
    href: 'https://pharma-guide-portal.nyc3.digitaloceanspaces.com/SupportClient/Splashtop_Streamer_Windows_DEPLOY_INSTALLER_v3.4.2.2_TSXRL5LRXSH7.exe',
  },
  // {
  //   title: 'Stripe Customer Portal',
  //   icon: mdiCardAccountDetailsStarOutline,
  //   // eslint-disable-next-line arrow-body-style
  //   to: 'customer-portal',
  // },
  // {
  //   title: 'Customer Portal',
  //   icon: mdiCardAccountDetailsStarOutline,
  //   to: 'portal',
  // },
  // {
  //   title: 'People',
  //   icon: mdiAccountMultiple,
  //   to: 'company',
  //   badge: 'Active',
  //   badgeColor: 'success',
  // },
  // {
  //   title: 'Calendar',
  //   icon: mdiCalendarMonth,
  //   to: 'apps-calendar',
  //   badge: 'Active',
  //   badgeColor: 'success',
  // },
  // {
  //   title: 'Lists',
  //   icon: mdiFormatListBulletedTriangle,
  //   to: 'tempchart',
  //   badge: 'Active',
  //   badgeColor: 'success',
  // },
  // {
  //   title: 'Labelling',
  //   icon: mdiPrinterSettings,
  //   // to: 'second-page',
  // },
  // {
  //   title: 'Communication',
  //   icon: mdiForumOutline,
  //   // to: 'second-page',
  // },
  // {
  //   title: 'Reporting',
  //   icon: mdiFileChart,
  //   // to: 'second-page',
  // },

  // {
  //   title: 'Settings',
  //   icon: mdiCogOutline,
  //   // to: 'second-page',
  // },
  // {
  //   title: 'Supplies',
  //   icon: mdiCartMinus,
  //   // to: 'second-page',
  // },
  // {
  //   title: 'Training',
  //   icon: mdiSchool,
  //   // to: 'second-page',
  // },
]
