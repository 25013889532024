/* eslint-disable no-undef */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard } from '../auth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    beforeEnter: authGuard,
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/enrollment',
    name: 'enrollment',
    component: () => import('@/views/Enrollment.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('@/views/Portal.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
    beforeEnter: authGuard,
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
